import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_TICKET,
    DELETE_TICKET,
    TICKET_FORM_TOOGLE_LOADING,
    SET_TICKET_LIST,
    SHOW_NOTIFICATION,
    UPDATE_TICKET,
} from "../constants";
import { formatTickets } from "./settingsActionsUtils";

/* TICKET LIST */
export const fetchTickets = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.tickets.list)
            .then((response) => {
                const tickets = formatTickets(response.data);
                dispatch({
                    type: SET_TICKET_LIST,
                    payload: keyBy(tickets, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// TICKETS
export const createTicket = (newTicket) => {
    return async (dispatch) => {
        dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.tickets.create, newTicket)
            .then((response) => {
                const ticket = formatTickets(response.data);
                dispatch({ type: ADD_NEW_TICKET, payload: ticket });
                dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Ticket creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateTicket = (updatedTicket) => {
    return async (dispatch) => {
        dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.tickets.edit}${updatedTicket && updatedTicket._id}`, updatedTicket)
            .then((response) => {
                const ticket = formatTickets(response.data);
                dispatch({ type: UPDATE_TICKET, payload: ticket });
                dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ticket actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteTickets = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.tickets.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_TICKET, payload: Ids });
                dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Ticket eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: TICKET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
