import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  applications,
  applicationsForm,
  tickets,
  ticketsForm,
} from "./Reducers";

export default combineReducers({
  tickets,
  ticketsForm,
  applications,
  applicationsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
