export const API = {
	tickets: {
    create: `/ticket`,
    edit: `/ticket/`,
    delete: `/ticket/`,
    list: `/ticket`,
  },
	applications: {
    create: `/application`,
    edit: `/application/`,
    delete: `/application/`,
    list: `/application`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
